import './Roadmap.css'
import Navbar from "../Navbar/Navbar"
import {ethers} from 'ethers';
import AnimatedPage from '../../utils/AnimatedPage';
import Snackbar from "../../utils/snackbar";
import React, { useState, useEffect, useRef } from 'react'; 
import { Button, Spinner } from 'react-bootstrap'
import ReactiveButton from 'reactive-button';
import Footer from "../Footer/Footer"
import contractJson from "../../../template.json";
import logo from "./../../images/roadmap2.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const contractAddress = "";
const SnackbarType = {
    success: "success",
    fail: "fail",
};


function Roadmap(){
    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")
    const snackbarRef = useRef(null);


    return(
    <div className='container2'>
        <Navbar />
        
        <div className='background-home'>
                <AnimatedPage>
                <div className='page-contents'>
                        <Snackbar
                            ref={snackbarRef}
                            message={snackBarMessage}
                            type={snackBarType}
                        />
                        <div className='title'> Roadmap
                        </div>
                        <div className='roadMapSections'>
                            <div className='leftRoadmap'>
                                <div className='roadmapFullContainer'>
                                    <div className='roadmapBox'>
                                        <h3>6300</h3>
                                        <p>Organize In-person events for holders. Certain events will be fun
                                            MetaJunk networking events while others will be events aimed at
                                            promoting Men’s mental health.</p>
                                    </div>
                                    <div className='horizontal_dotted_line_shorter'></div>
                                </div>
                                <div className='roadmapFullContainer'>
                                    <div className='roadmapBox'>
                                        <h3>4900</h3>
                                        <p>Surprise! (Mystery Airdrop?) ;)</p>
                                    </div>
                                    <div className='horizontal_dotted_line'></div>
                                </div>
                                <div className='roadmapFullContainer'>
                                    <div className='roadmapBox'>
                                        <h3>3500</h3>
                                        <p>Open Metajunks Merch store with real and virtual merch.</p>
                                    </div>
                                    <div className='horizontal_dotted_line'></div>
                                </div>
                                <div className='roadmapFullContainer'>
                                    <div className='roadmapBox'>
                                        <h3>2100</h3>
                                        <p>Big MetaJunk Giveaway to thank early supporters</p>
                                    </div>
                                    <div className='horizontal_dotted_line'></div>
                                </div>
                                <div className='roadmapFullContainer'>
                                    <div className='roadmapBox'>
                                    <h3>1400</h3>
                                        <p>We send MetaJunk Condoms with an image of your very own
                                            Metajunk to the first 1,400 holders</p>
                                    </div>
                                    <div className='horizontal_dotted_line_shorter'></div>
                                </div>
                                <div className='roadmapFullContainer'>
                                    <div className='roadmapBoxFilled'>
                                        <h3>0</h3>
                                        <p>Launch and enjoy</p>
                                    </div>
                                    <div className='horizontal_dotted_line_shorter'></div>
                                </div>
                            </div>
                            <div className='middleRoadmap'>
                                <img src={logo}></img>
                            </div>
                            <div className='rightRoadmap'>
                                <div className='roadmapFullContainer'>
                                    <div className='horizontal_dotted_line_right'></div>
                                    <div className='roadmapBox'>
                                        <h3>6969</h3>
                                        <p>Anonymous <a href='https://www.MetaJunks.gg/MetaMental'>Mental Health Platform</a> is released.</p>
                                    </div>
                                </div>
                                <div className='roadmapFullContainer'>
                                    <div className='horizontal_dotted_line_shorter_right'></div>
                                    <div className='roadmapBox'>
                                        <h3>5600</h3>
                                        <p>Release MetaJunk DAO which gives voting rights to holders on
                                            the way the <a href='https://www.MetaJunks.gg/MetaMental'>Mental Health Platform</a> is run and developed. DAO members will be
                                            able to profit from the platform’s success.</p>
                                    </div>
                                </div>
                                <div className='roadmapFullContainer'>
                                    <div className='horizontal_dotted_line_shorter_right'></div>
                                    <div className='roadmapBox'>
                                        <h3>4200</h3>
                                        <p>Begin Collabs with prominent influencers to raise more
                                            awareness for Men’s Mental Health.</p>
                                    </div>
                                </div>
                                <div className='roadmapFullContainer'>
                                    <div className='horizontal_dotted_line_shorter_right'></div>
                                    <div className='roadmapBox'>
                                        <h3>2800</h3>
                                        <p>Release Private Discord channel with active psychologists and
                                            discussion topics</p>
                                    </div>
                                </div>
                                <div className='roadmapFullContainer'>
                                    <div className='horizontal_dotted_line_right'></div>
                                    <div className='roadmapBox'>
                                            <h3>699</h3>
                                        <p>The MetaJunks team individually rubs one out in honor of each of
                                            the first 699 MetaJunk owners.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='roadMapSections-mobile'>
                        <div className='roadmapFullContainer'>
                                <div className='roadmapBoxFilled'>
                                    <h3>0</h3>
                                    <p>Launch and enjoy</p>
                                </div>
                            </div>
                            <div className='roadmapFullContainer'>
                                <div className='roadmapBox'>
                                    <h3>699</h3>
                                    <p>The MetaJunks team individually rubs one out in honor of each of
                                        the first 699 MetaJunk owners.</p>
                                </div>
                            </div>
                            <div className='roadmapFullContainer'>
                                <div className='roadmapBox'>
                                    <h3>1400</h3>
                                    <p>We send MetaJunk Condoms with an image of your very own Metajunk to the first 1,400 holders.</p>
                                </div>
                            </div>
                            <div className='roadmapFullContainer'>
                                <div className='roadmapBox'>
                                    <h3>2100</h3>
                                    <p>Big MetaJunk Giveaway to thank early supporters.</p>
                                </div>
                            </div>
                            <div className='roadmapFullContainer'>
                                <div className='roadmapBox'>
                                    <h3>2800</h3>
                                    <p>Release Private Discord channel with active psychologists and discussion topics.</p>
                                </div>
                            </div>
                            <div className='roadmapFullContainer'>
                                <div className='roadmapBox'>
                                    <h3>3500</h3>
                                    <p>Open Metajunks Merch store with real and virtual merch.</p>
                                </div>
                            </div>
                            <div className='roadmapFullContainer'>
                                <div className='roadmapBox'>
                                    <h3>4200</h3>
                                    <p>Begin Collabs with prominent influencers to raise more awareness for Men’s Mental Health.</p>
                                </div>
                            </div>
                            <div className='roadmapFullContainer'>
                                <div className='roadmapBox'>
                                    <h3>4900</h3>
                                    <p>Surprise!  (Mystery Airdrop?) ;)</p>
                                </div>
                            </div>
                            <div className='roadmapFullContainer'>
                                <div className='roadmapBox'>
                                    <h3>5600</h3>
                                    <p>Release MetaJunk DAO which gives voting rights to holders on the way the <a href='https://www.MetaJunks.gg/MetaMental'>Mental Health Platform</a> is run and developed. DAO members will be able to profit from the platform’s success.</p>
                                </div>
                            </div>
                            <div className='roadmapFullContainer'>
                                <div className='roadmapBox'>
                                    <h3>6300</h3>
                                    <p>Organize In-person events for holders. Certain events will be fun MetaJunk networking events while others will be events aimed at promoting Men’s mental health.</p>
                                </div>
                            </div>
                            <div className='roadmapFullContainer'>
                                <div className='roadmapBox'>
                                    <h3>6969</h3>
                                    <p>Anonymous <a href='https://www.MetaJunks.gg/MetaMental'>Mental Health Platform</a> is released.</p>
                                </div>
                            </div>
                            <div className="fadeAboveFooterJunks"></div>
                            {/* <div className='backgroundExtender-roadmap'></div> */}
                        </div>
                </div>
                    
            </AnimatedPage>
        </div>
        <div className='backgroundExtender-roadmap'>
        </div>
        <div className="fadeAboveFooter"></div>
        <Footer/>
    </div>
    )
}

export default Roadmap;