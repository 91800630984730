import './MetaMental.css'
import Navbar from "../Navbar/Navbar"
import {ethers} from 'ethers';
import AnimatedPage from '../../utils/AnimatedPage';
import Snackbar from "../../utils/snackbar";
import React, { useState, useEffect, useRef } from 'react'; 
import { Button, Spinner } from 'react-bootstrap'
import ReactiveButton from 'reactive-button';
import Footer from "../Footer/Footer"
import Footer2 from "../Footer/Footer2"
import contractJson from "../../../template.json";
import logo from "./../../images/roadmap2.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const contractAddress = "";
const SnackbarType = {
    success: "success",
    fail: "fail",
};


function MetaMental(){
    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")
    const snackbarRef = useRef(null);


    return(
    <div className='container2'>
        <Navbar />
        
        <div className='background-home'>
                <AnimatedPage>
                <div className='page-contents-metameat'>
                        <Snackbar
                            ref={snackbarRef}
                            message={snackBarMessage}
                            type={snackBarType}
                        />
                        <div className='background-junks'>
                        <div className='title'> MetaMental
                        </div>
                        <div className='MetaMentalInnerContents'>
                            <div className='WhatIsMetaMental'>
                                <div className='text-holder-metameat'>
                                    <h1>What is MetaMental?</h1>
                                    <p>A platform that not only provides one-on-one therapy sessions with licensed psychologists in
                                    an anonymous setting but also serves as an anonymous social media platform where users can
                                    discuss their mental health concerns.</p>
                                    <p>You will be able to submit questions and sentiments in a forum where licensed psychologists
                                    and other people who are experiencing similar feelings will respond to your postings and discuss
                                    them.</p>
                                    <p>Group meetings/discussions led by a psychologist addressing a specific issue (depression,
                                    alcoholism, eating disorder, heartbreak, etc.)</p>
                                    <p>Payments will be fully made with crypto (or NFTpay), so we keep the identity of the users
                                    hidden.</p>
                                    <p>Everything will be held fully anonymous, so we let men be vulnerable without feeling
                                    emasculated.</p>
                            </div>
                        </div>
                            <div className='OurMission'>
                            <div className='text-holder-metameat'>
                                    <h1>Our Mission</h1>
                                    <p>Our mission is to create a space where men (and women) can get help fully anonymously from
                                    real professionals all over the world with a click of a couple of buttons. No matter your issue,
                                    you can get helped and heard by the world&#39;s best in the field and lift that boulder off of your
                                    shoulders.</p>
                                    <p>We are in the process of establishing a platform that will allow users to receive assistance from
                                    actual professionals while maintaining complete anonymity. The website not only provides one-
                                    on-one and group therapy sessions conducted in complete anonymity with qualified
                                    psychologists, but it also serves as a social platform on which individuals can chat anonymously
                                    about their mental health. We are aware of how difficult it is for men to speak up, and we do not
                                    judge them for their reluctance to do so. No man wants to seem cowardly or feeble as a result of
                                    the challenges he faces. All transactions will be made through the blockchain offering payments
                                    with different cryptocurrencies for 100% anonymous transactions.</p>
                            </div>
                            </div>
                            <div className='OurGoal'>
                            <div className='text-holder-metameat'>
                                    <h1>What is the goal of MetaJunks?</h1>
                                    <p>The NFT project that we are working on aims to combine the most desirable aspects of the real
                                        and web3 worlds. Being a member of our community entitles you to all of the perks that are
                                        typically associated with community-based projects, such as invitations to in-person holder
                                        events, exclusive access to Discord, giveaways, and airdrops. However, you will also have the
                                        opportunity to contribute to the funding of a venture that is not limited to the NFT community
                                        alone. Our mission is to establish a venue in which individuals can freely discuss any aspect of
                                        their mental health, be completely anonymous while doing so, and not feel guilty about their
                                        struggles. <a href='https://www.MetaJunks.gg/Mint'>Minting</a> our NFT will allow us to further fund the creation of this platform with
                                        maximum quality, fulfilling our true vision.</p>
                                        <p>We are working on a DAO that every member will have access to. In the DAO, holders will be able to help develop and oversee the platform. Ultimately, we want to allocate profits from the platform’s success to our members.</p>
                            </div>
                            </div>
                        </div>
                        
                        <Footer2/>
                        </div>
                        <div className="fadeAboveFooterJunks"></div>
                </div>
                    
            </AnimatedPage>
            </div>
            {/* <div className="fadeAboveFooter"></div> */}
        {/* <Footer/> */}
    </div>
    )
}

export default MetaMental;