import React, { useState, useEffect, useRef } from 'react'; 
import Navbar from "../Navbar/Navbar"
import "./home.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faTwitterSquare } from "@fortawesome/free-brands-svg-icons"
import ReactiveButton from 'reactive-button';
import MintButton from '../../minter/MintButton'
import Footer from "../Footer/Footer";
import Video from "./../../utils/Videos/Video"
import AOS from 'aos';
import 'aos/dist/aos.css';
import spaceJunk from "./../../images/spaceJunk.jpg"
import junksGIF from "./../../images/junksGIF.gif"
import AnimatedPage from '../../utils/AnimatedPage';

AOS.init();

function Home() {
  const [background, SetBackground] = useState()
  let mainGif = "https://metajunks.s3.eu-west-2.amazonaws.com/mainGif.gif"

  const [ageVerify, SetAgeVerify] = useState(false)
  const [privacyVerify, SetPrivacyVerify] = useState(false)
  const [mintDisabled, SetMintDisabled] = useState(true)

  //const junksGifs = "https://metajunks.s3.eu-west-2.amazonaws.com/images/junksGIF.gif"

  function callMint(){
      console.log("test")
  }

  return (
    <div className="home-container">
        <Navbar />
            <div className='background-home'> 
            <AnimatedPage>          
                <div className='page-contents-home'>
                    <div className='title'> Home of the Junks
                    </div>
                    <div data-aos="fade-left" className='right-mobile'>
                            <div className='img-container'>
                                <img src={mainGif}/>
                            </div>
                        </div>
                    <div className='page-sections-home'>
                        <div className='left'>
                            <div data-aos="fade-right" className='text-holder'>
                                <h1>Who are we?</h1>
                                <p>
                                    MetaJunks is a collection of 6,969 high-quality Junks that are generated at random to provide a
                                    perfect and one-of-a-kind piece for each of its holders.
                                </p>
                                <p>
                                    Stored in the Ethereum blockchain and thriving in your pants.
                                </p><p>
                                     
                                    What was at first a meme project focused solely on high-quality artwork and humorous content
                                    has rapidly evolved into an ambitious idea for a <a href='https:gg/MetaMental'>Mental Health Platform</a> that has the potential
                                    to aid millions of people all around the world and tackle one of the most pressing issues facing
                                    the world today. Mental health is commonly overlooked or even stigmatized, and this is
                                    especially prevalent among men. When males talk about their mental health, they face
                                    tremendous amounts of stigma. Because men do not want to appear emasculated, devalued, and
                                    weak. This social flaw is quite natural. It is understandable that we would like to project an
                                    image of resiliency to the rest of the world, but unfortunately, doing so is not as simple as it may
                                    seem. MetaJunks serves as a mascot for strong, healthy, and happy men. MetaJunks stay hard so
                                    that men worldwide can too.
                                </p>
                            </div>

                        </div>
                        <div data-aos="fade-left" className='right'>
                            <div className='img-container'>
                                {/* <img src={spaceJunk}/> */}
                                <img src={mainGif}/>
                            </div>
                        </div>
                    </div>
                    <div className='bottomQuotes'>
                        <div className='leftQuoteBox'>
                            <h3>“There&#39;s a stigma in this world that men can&#39;t talk. Listen, if you&#39;re a man and you&#39;ve got
                                weight on your shoulders and think the only way you can solve it is by killing yourself,
                                please speak to someone! Speak to anyone,”
                                - Paddy “The Baddy” Pimblett</h3>
                        </div>
                        <div className='rightQuoteBox'>
                            <h3>&quot;As a matter of fact, asking for help is our superpower, and men,
                            especially us, we fall into this trap of being really adverse to
                            vulnerability because we always want to be strong and feel like we
                            can take on the world.&quot;
                            - Dwayne “The Rock” Johnson</h3>
                        </div>
                    </div>
                    <div className='bottomQuotesMobile'>
                        <div className='leftQuoteBoxMobile'>
                            <h3>“There&#39;s a stigma in this world that men can&#39;t talk. Listen, if you&#39;re a man and you&#39;ve got
                                weight on your shoulders and think the only way you can solve it is by killing yourself,
                                please speak to someone! Speak to anyone,”
                                - Paddy “The Baddy” Pimblett</h3>
                        </div>
                        <div className='rightQuoteBoxMobile'>
                            <h3>&quot;As a matter of fact, asking for help is our superpower, and men,
                            especially us, we fall into this trap of being really adverse to
                            vulnerability because we always want to be strong and feel like we
                            can take on the world.&quot;
                            - Dwayne “The Rock” Johnson</h3>
                        </div>
                    </div>
                </div>  
                </AnimatedPage>
    
                <div className="fadeAboveFooter"></div>
            </div> 
            <Footer/> 
      </div>
  );
}

export default Home;