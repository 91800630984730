export const MenuList = [
  {
    title: "Home",
    url: "/Home",
  },
  {
    title: "Meat The Junks",
    url: "/Meatthejunks",
  },
  {
    title: "1/1",
    url: "/1-1",
  },
  {
    title: "Roadmap",
    url: "/Roadmap",
  },
  {
    title: "MetaMental",
    url: "/MetaMental",
  },
  {
    title: "Mint",
    url: "/Mint",
  }
];
