import './MeatTheJunks.css'
import Navbar from "../Navbar/Navbar"
import AnimatedPage from '../../utils/AnimatedPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useRef } from 'react'; 
import Snackbar from "../../utils/snackbar";
import Footer from "../Footer/Footer"
import Footer2 from "../Footer/Footer2"
import AOS from 'aos';
import 'aos/dist/aos.css';
import image1 from "./../../images/1.jpg"
import img1 from "./../../images/01.png"
import img2 from "./../../images/02.png"
import img3 from "./../../images/03.png"
import img4 from "./../../images/04.png"
import img5 from "./../../images/05.png"
import img6 from "./../../images/06.png"
import img7 from "./../../images/07.png"
import img8 from "./../../images/08.png"
import img9 from "./../../images/09.png"
import img10 from "./../../images/10.png"
import { faInstagram, faTwitterSquare, faDiscord, faTwitch} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

AOS.init();

const SnackbarType = {
  success: "success",
  fail: "fail",
};


const MeatTheJunks = () => {
    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")
    const snackbarRef = useRef(null);

    // We listen to the resize event
        // We execute the same script as before
    // useEffect(() => {
    //     window.addEventListener('resize', () => {
    //         let vh = window.innerHeight * 0.01;
    //         document.documentElement.style.setProperty('--vh', `${vh}px`);  
    //     });
    // })

    let Dyor = "https://metajunks.s3.eu-west-2.amazonaws.com/images/Dyor+Junk.JPG"
    let Etherman = "https://metajunks.s3.eu-west-2.amazonaws.com/images/MAYC+%233830.png"
    let Mars = "https://metajunks.s3.eu-west-2.amazonaws.com/images/mars+junk.JPG"
    let Dragond = "https://metajunks.s3.eu-west-2.amazonaws.com/images/DragonD+Junk.JPG"
    let Arnas = "https://metajunks.s3.eu-west-2.amazonaws.com/images/Arkunas+Junk.JPG"
    let Timmy = "https://metajunks.s3.eu-west-2.amazonaws.com/images/Timmy.png"

    return(
        <div className='container2'>   
            <Navbar /> 
            <div className='blackBackgroundMeatJunks'>
                <div className='background-junks'>
                    <AnimatedPage>
                        <div className='page-contents-pc'>
                            <div className='background-junks'>
                                <Snackbar
                                    ref={snackbarRef}
                                    message={snackBarMessage}
                                    type={snackBarType}
                                />
                                <div className='title'> Meat the Junks
                                </div>
                                <div className='topJunksRow'>
                                    <div className='junkDescriptionContainer'>
                                        <h2>Dyor</h2>
                                        <img src={Dyor}></img>
                                        <h4>Co-Founder</h4>
                                    </div>
                                    <div className='junkDescriptionContainer'>
                                        <h2>Ethereman</h2>
                                        <img src={Etherman}></img>
                                        <h4>Co-Founder</h4>
                                    </div>
                                    <div className='junkDescriptionContainer'>
                                        <h2>Mars</h2>
                                        <img src={Mars}></img>
                                        <h4>Marketing Advisor</h4>
                                    </div>
                                </div>
                                <div className='middleJunksRow'>
                                    <div className='junkDescriptionContainer'>
                                        <h2>Dragond</h2>
                                        <img src={Dragond}></img>
                                        <div className='teamTwitter'>
                                            <h4>Head Artist</h4>
                                            <a href="https://twitter.com/xdilshanabey" target="_blank"
                                                    name="twitter" className="teamTwitter">
                                                    <FontAwesomeIcon icon={faTwitterSquare} className="twitterImg"/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='junkDescriptionContainer'>
                                        <h2>Arnas & ARKUNAS Team</h2>
                                        <img src={Arnas}></img>
                                        <h4>Discord Expert</h4>
                                    </div>
                                </div>
                            </div>
                            <div className='spacer'/>
                            <Footer2/>
                        </div>
                        <div className="fadeAboveFooterJunks"></div>
                        <div className='page-contents-mobile'>
                            <Snackbar
                                ref={snackbarRef}
                                message={snackBarMessage}
                                type={snackBarType}
                            />
                            <div className='title'> Meat the Junks
                            </div>
                            <div className='mobile-junk-row'>
                                <div className='junkDescriptionContainer'>
                                    <h2>Dyor</h2>
                                    <img src={Dyor}></img>
                                    <h4>Co-Founder</h4>
                                </div>
                                <div className='junkDescriptionContainer'>
                                    <h2>Ethereman</h2>
                                    <img src={Etherman}></img>
                                    <h4>Co-Founder</h4>
                                </div>
                                <div className='junkDescriptionContainer'>
                                    <h2>Mars</h2>
                                    <img src={Mars}></img>
                                    <h4>Marketing Advisor</h4>
                                </div>
                                <div className='junkDescriptionContainer'>
                                    <h2>Dragond</h2>
                                    <img src={Dragond}></img>
                                    <div className='teamTwitter'>
                                            <h4>Head Artist</h4>
                                            <a href="https://twitter.com/xdilshanabey" target="_blank"
                                                    name="twitter" className="teamTwitter">
                                                    <FontAwesomeIcon icon={faTwitterSquare} className="twitterImg"/>
                                            </a>
                                    </div>
                                </div>
                                <div className='junkDescriptionContainer'>
                                    <h2>Arnas &amp; ARKUNAS Team</h2>
                                    <img src={Arnas}></img>
                                    <h4>Discord Expert</h4>
                                </div>
                                <div className='junkDescriptionContainer'>
                                    <h2>Timmy</h2>
                                    <img src={Timmy}></img>
                                    <h4>Lead Dev</h4>
                                </div>
                            </div>
                            <Footer2/>
                        </div>
                        {/* <div className="fadeAboveFooterJunks"></div> */}
                    </AnimatedPage>
                </div>
                {/* <div className='backgroundExtenderMeatTeam'>
                </div> */}
            </div>
            {/* <div className="fadeAboveFooter"></div> */}
            {/* <Footer/> */}
        </div>
    
    )
}

export default MeatTheJunks;