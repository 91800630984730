import React, { useState, useEffect, useRef } from 'react'; 
import "./LandingPage.css"
import glove from "./../../images/GloveSVG.svg"
import junk from "./../../images/logo.png"
import Draggable from 'react-draggable';
import {Redirect, Route} from "react-router-dom";

function LandingPage() {

  const [handDisplay, setHandDisplay] = useState("block")
  // const [xAxis, setXAxis] = useState(200)
  // const [yAxis, setYAxis] = useState(-550)
  // const [xAxis, setXAxis] = useState(280)
  // const [yAxis, setYAxis] = useState(-730)
  const [topRange, setTopRange] = useState(-170)
  const [bottomRange, setBottomRange] = useState(20)
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [glovePosition, setGlovePosition] = useState(0)
  const [redirect, setRedirect] = useState(false)


  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    console.log(windowDimensions)

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    if(width < 655){
      //setTopRange(-170)
      //setBottomRange(20)
    }

    return {
      width,
      height
    };
  }

  function showHand(){
    setHandDisplay("block")
  }

  function hideHand(){
    setHandDisplay("none")
  }

  function getYOfGlove(){
    var myEl = document.getElementById('glove').style.transform;
    var glovePosition = myEl.substring(
      myEl.indexOf("-") + 1, 
      myEl.lastIndexOf("p")
    );
    console.log(glovePosition);  
    setGlovePosition(glovePosition)

    if(glovePosition > 169){
      setRedirect(true)
    }
  }


  return (
    <div className="landingPageBK">
      <div className='landingTitle'>
        <h1>MetaJunks</h1>
      </div>
      <div className='landingImage' onMouseOver={showHand} onMouseOut={hideHand}>
        {/* <div className='junkImg'>
          <img src={junk}></img>
        </div> */}
        <div className='box'></div>
        <div className='imgBox'>
          <Draggable axis='y' bounds={{top: topRange, bottom: bottomRange}} onDrag={getYOfGlove}>
           {/* positionOffset={{x: '50%', y: '-150%'}}>
              defaultPosition={{x: xAxis, y: yAxis}}>
              defaultPosition={{x: 200, y: -550}}> */}
              <img id='glove' style={{display:handDisplay}} src={glove} draggable="false"></img>         
          </Draggable>
        </div>
      </div>
      <Route exact path="/" render={() => (
                        !redirect ? (
                            <Redirect to="/"/>
                        ) : (
                            <Redirect to="/Home"/>
                        )
                        )}/>
      <div className='landingText'>
        <h2>Jerk Up To Enter</h2>
      </div>
    </div>
  );
}

export default LandingPage;