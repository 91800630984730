import React, { useState, useEffect, useRef } from 'react'; 
import Navbar from "../Navbar/Navbar"
import "./Mint.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faTwitterSquare } from "@fortawesome/free-brands-svg-icons"
import ReactiveButton from 'reactive-button';
import MintButton from '../../minter/MintButton'
import Footer from "../Footer/Footer";
import Video from "./../../utils/Videos/Video"
import AnimatedPage from '../../utils/AnimatedPage';
import AOS from 'aos';
import 'aos/dist/aos.css';
import mintButton from "./../../images/mintButton.png"
AOS.init();

function Mint() {
  const [mintCounter, SetMintCounter] = useState(false)

  function callMint(){
      console.log("test")
  }

  function mintNFT(){

  }

  function getCounter(){

  }


  return (
    <div className="home-container">
        <Navbar />
            <div className='background-home'> 
                <AnimatedPage>        
                    <div className='page-contents'>
                        <div className='title'> Mint
                        </div>
                        <div className='page-sections-mint'>
                            <div data-aos="fade-left" className='right-mint'>
                                <div className='mintButtonContainer'>
                                    <div className='innerMintContainer'>
                                        <p>0.069 Eth</p>
                                        <img src={mintButton} onClick={mintNFT}></img>
                                        <p>{mintCounter}0 / 6969 Junks Jerked</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='page-sections-mint-mobile'>
                            <div className='right-mint'>
                                <div className='mintButtonContainer'>
                                    <div className='innerMintContainer'>
                                        <p>0.069 Eth</p>
                                        <img src={mintButton} onClick={mintNFT}></img>
                                        <p>{mintCounter}0 / 6969 Junks Jerked</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>  
                    <div className="fadeAboveFooter"></div> 
                </AnimatedPage>          
            </div> 
            <Footer/> 
      </div>
  );
}

export default Mint;