import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import LandingPage from "../pages/LandingPage/LandingPage";
import Home from "../pages/home/home";
import Mint from '../pages/Mint/Mint';
import MeatTheJunks from "../pages/MeatTheJunks/MeatTheJunks";
import Roadmap from '../pages/Roadmap/Roadmap';
import OneOfKinds from "../pages/1-1s/1-1s"
import MetaMental from '../pages/MetaMental/MetaMental';

import { AnimatePresence } from 'framer-motion/dist/framer-motion';

function AnimatedRoutes() {
    const location = useLocation();

    return(
        <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
                <Route path="/" exact component={LandingPage} />
                <Route path="/Home" exact component={Home} />
                <Route path="/Mint" exact component={Mint}/>
                <Route path="/1-1" exact component={OneOfKinds}/>
                <Route path="/MeatTheJunks" exact component={MeatTheJunks}/>
                <Route path="/Roadmap" exact component={Roadmap}/>
                <Route path="/MetaMental" exact component={MetaMental}/>
            </Switch>
        </AnimatePresence>
    );
}

export default AnimatedRoutes