import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faTwitterSquare, faDiscord, faTwitch} from "@fortawesome/free-brands-svg-icons"
import './Footer.css'
import logo from "./../../images/logo.png"

const Footer = () => {


    return(
        <div>
            {/* <div className="fadeAboveFooter"></div> */}
            <div className="background-footer">
                    <div className="footer-bottom">
                        <div className="col1">
                            <div className="footer-info">
                                <div className="footer-logo-container">
                                    <a className="footer-logo" href="#">
                                        <img src={logo}/>
                                    </a>
                                </div>
                            </div>                                      
                        </div>
                        <div className="col2">
                            <p><a href="http://www.metajunks.gg">Home</a></p>
                            {/* <p><a href="http://www.localhost:300/FAQ">FAQ</a></p> */}
                            <p className="copyright">©2022 MetaJunks. All rights reserved.</p>
                        </div>
                        <div className="col3">
                            <div className="socials-col2">
                                <div className="link"><a href="https://twitter.com/MetaJunksNFT" target="_blank"
                                        name="twitter" className="twitter row">
                                        <FontAwesomeIcon icon={faTwitterSquare} />
                                    </a>
                                </div>
                                <div className="link">
                                    <a href="https://discord.gg/Q7QPy4anZj" target="_blank" name="discord"
                                        className="discord row"><FontAwesomeIcon icon={faDiscord} />
                                    </a>
                                </div>
                            </div>                        
                        </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;