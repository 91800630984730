import './1-1s.css'
import Navbar from "../Navbar/Navbar"
import AnimatedPage from '../../utils/AnimatedPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useRef } from 'react'; 
import Snackbar from "../../utils/snackbar";
import Footer from "../Footer/Footer"
import Footer2 from "../Footer/Footer2"
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

const SnackbarType = {
  success: "success",
  fail: "fail",
};


const OneOfKinds = () => {
    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")
    const snackbarRef = useRef(null);
    let img1 = "https://metajunks.s3.eu-west-2.amazonaws.com/images/01.png"
    let img2 = "https://metajunks.s3.eu-west-2.amazonaws.com/images/02.png"
    let img3 = "https://metajunks.s3.eu-west-2.amazonaws.com/images/03.png"
    let img4 = "https://metajunks.s3.eu-west-2.amazonaws.com/images/04.png"
    let img5 = "https://metajunks.s3.eu-west-2.amazonaws.com/images/05.png"
    let img6 = "https://metajunks.s3.eu-west-2.amazonaws.com/images/06.png"
    let img7 = "https://metajunks.s3.eu-west-2.amazonaws.com/images/07.png"
    let img8 = "https://metajunks.s3.eu-west-2.amazonaws.com/images/08.png"
    let img9 = "https://metajunks.s3.eu-west-2.amazonaws.com/images/09.png"
    let img10 = "https://metajunks.s3.eu-west-2.amazonaws.com/images/10.png"

    // We listen to the resize event
        // We execute the same script as before
    // useEffect(() => {
    //     window.addEventListener('resize', () => {
    //         let vh = window.innerHeight * 0.01;
    //         document.documentElement.style.setProperty('--vh', `${vh}px`);  
    //     });
    // })


    return(
        <div className='container2'>   
            <Navbar /> 
            <div className='blackBackground'>
                <div className='background-junks'>
                    <AnimatedPage>
                        <div className='page-contents-pc'>
                            <div className='background-junks'>
                                <Snackbar
                                    ref={snackbarRef}
                                    message={snackBarMessage}
                                    type={snackBarType}
                                />
                                <div className='title'> 1/1 Inspirations 
                                </div>
                                <div className='topJunksRow'>
                                    <div className='junkDescriptionContainer'>
                                        <div className='topHalf'>
                                            <h2>ELONgated MUSKeteer</h2>
                                            <img src={img1}></img>
                                        </div>
                                        <div className='bottomHalf'>
                                            <p>Elon Musk was the first 1/1 that sprang
                                                to our minds and the first person who we wanted to see as a Junk.
                                                He is inspiring a whole generation of innovative thinkers and doers to
                                                steer the ship of humanity in the right direction. Because he is such a
                                                big lover of memes and also of NFTs, with his assistance, we will be
                                                able to impact the lives of a great number of people and disseminate
                                                awareness about mental health and MetaJunks all over Twitter. Elon
                                                is our symbol of innovation and reaching heights never before
                                                thought possible. This NFT is Elon&#39;s and our project&#39;s talisman to
                                                blast past the moon and make it all the way to mars. ​​
                                            </p>
                                        </div>
                                    </div>
                                    <div className='junkDescriptionContainer'>
                                        <div className='topHalf'>
                                            <h2>Mark’s got a Cuban</h2>
                                            <img src={img2}></img>
                                        </div>
                                        <div className='bottomHalf'>
                                            <p>
                                                Inspired by Mark Cuban. He is not only a
                                                shark in the streets and a maverick in the sheets, but he is also a
                                                F*CKING successful businessman and an avid NFT collector. We
                                                know that Mark wants to leave a positive mark on the world with his
                                                significant strides toward revolutionizing the online pharmaceutical
                                                industry, which enables millions of people to obtain the essential
                                                medication they require, a significant number of which assist
                                                individuals with their mental health. We feel that Mark&#39;s vision
                                                matches our aim to improve the world of mental health and help
                                                millions of people, and we look forward to working together to
                                                accomplish these goals.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='junkDescriptionContainer'>
                                    <div className='topHalf'>
                                        <h2>Gary’s Wee</h2>
                                        <img src={img3}></img>
                                    </div>
                                    <div className='bottomHalf'>
                                        <p>Inspired by the whale Gary Vee. You can see the
                                            cute jersey his grandma knitted and the ugly wires on his earphones.
                                            Gary opened people&#39;s eyes to NFTs, so we opened his pants to

                                            create this phenomenal piece. Gary is an advocate of leading a
                                            happy life and has motivated a great number of individuals to take
                                            action to make positive changes in their lives. Because Gary
                                            demonstrated to the world what can be accomplished with a shift in
                                            mentality, we have honored him with this 1/1.
                                        </p>                                
                                    </div>

                                    </div>
                                </div>
                                <div className='middleJunksRow'>
                                    <div className='junkDescriptionContainer'>
                                        <div className='topHalf'>
                                            <h2>Steve Did it</h2>
                                            <img src={img4}></img>                                        
                                        </div>
                                        <div className='bottomHalf'>
                                            <p>
                                                This Junk is inspired by Steve Will do it. We wanted
                                                to show the world that he also has a large and beautiful heart;
                                                therefore we made the decision to demonstrate how massive and
                                                lovely his Junk is as well. Not only does Steve help those who are
                                                going through difficult times (no, we&#39;re not talking about the strippers),
                                                but he also always shows people the joyful side of life and
                                                demonstrates that life can be exciting and full of new experiences.
                                                Steve always manages to zero in on the reason why we should be
                                                celebrating the moment, no matter what it is. Steve stands as our
                                                symbol to party when we can and to always look for the path that
                                                comes with a good story to tell, which is what his honorary Junk
                                                symbolizes within our project.
                                            </p>                                        
                                        </div>

                                    </div>
                                    <div className='junkDescriptionContainer'>
                                        <div className='topHalf'>
                                            <h2>Millimeter Defeater</h2>
                                            <img src={img5}></img>                                    
                                        </div>
                                        <div className='bottomHalf'>
                                            <p>
                                                He is the smallest but most courageous Junk
                                                in the collection. Even though he is rather little, he possesses a large
                                                sack in addition to a massive heart. He is here to show people that
                                                size doesn&#39;t matter; what matters is your attitude towards the world
                                                and the strength of your character because it&#39;s not about the size of
                                                the ship; it&#39;s about the motion in the ocean.
                                            </p>                                    
                                        </div>
                                    </div>
                                </div>
                                <div className='topJunksRow'>
                                    <div className='junkDescriptionContainer'>
                                        <div className='topHalf'>
                                            <h2>The Cock</h2>
                                            <img src={img6}></img>                                  
                                        </div>
                                        <div className='bottomHalf'>
                                            <p>This gorgeous NFT is inspired by &quot;The Rock,&quot; also
                                                known as Dwayne Johnson. Dwayne once said: &quot;As a matter of fact,
                                                asking for help is our superpower, and men, especially us, we fall into
                                                this trap of being really adverse to vulnerability because we always
                                                want to be strong and feel like we can take on the world.&quot; This quote
                                                inspired us even more to create a space where men can get help
                                                anonymously and escape the trap of being adverse to vulnerability.
                                                The Rock serves as a metaphor for both mental and physical
                                                toughness; as a result, we were inspired to craft this stunning work of
                                                art. That and because we really wanted to be able to use “The Cock.”
                                                We’re sure you understand.
                                            </p>                                
                                        </div>
                                    </div>
                                    <div className='junkDescriptionContainer'>
                                        <div className='topHalf'>
                                            <h2>Big Shamcock</h2>
                                            <img src={img7}></img>                                
                                        </div>
                                        <div className='bottomHalf'>
                                            <p>
                                                This is inspired by a man who has had more jobs
                                                than Johnny Sins. We felt bad that Adam Sandler couldn&#39;t see Shaq&#39;s
                                                Junk, so we decided to show Mr. Sandler what it was all about.
                                                Unfortunately, We tried to fit the Junk true-to-size, but our computer
                                                kept crashing. Shaq stands as a symbol of doing what you love and
                                                playing to the strengths you&#39;ve been given, which is why we
                                                embodied his passion in this honorary 1/1. Get Junked On.
                                            </p>                             
                                        </div>
                                    </div>
                                    <div className='junkDescriptionContainer'>
                                        <div className='topHalf'>
                                        <h2>Snoop Dog</h2>
                                        <img src={img8}></img>                            
                                        </div>
                                        <div className='bottomHalf'>
                                            <p>
                                                This Junk is inspired by the one and only Snoop
                                                Dogg. Just like his joints, his Junk is never limp. Snoop serves as a
                                                symbol that sometimes the best and most important thing to do, is to
                                                chill the f*ck out. Often men get so caught up in everyday struggles
                                                that they forget to see the bigger picture and realize everything is
                                                okay, which is why this honorary NFT is a vital part of our message.
                                            </p>                             
                                        </div>
                                    </div>
                                </div>
                                <div className='middleJunksRow'>
                                    <div className='junkDescriptionContainer'>
                                        <div className='topHalf'>
                                            <h2>Abella's Danger</h2>
                                            <img src={img9}></img>                          
                                        </div>
                                        <div className='bottomHalf'>
                                            <p>
                                                The young lady known as Danger possesses
                                                a lot of bravery. She is in danger because she is usually used to only
                                                10 junks at a time, and we at Metajunks will serve her with 6,969 of
                                                them. There is nobody that loves Junks more than her, not even us.
                                                She adores them in every conceivable form, irrespective of their
                                                dimensions, hues, or other distinguishing features. We chose her to
                                                be part of the 1/1&#39;s because every man knows who she is, and she
                                                makes men imagine great things. We want her to help us show men
                                                that even if they think their mental health is in danger, they can get
                                                out of it with help. Unless they are literally IN Danger, in which case
                                                we bet they won&#39;t want any help.
                                            </p>                           
                                        </div>
                                    </div>
                                    <div className='junkDescriptionContainer'>
                                        <div className='topHalf'>
                                            <h2>Andrew's Cobra</h2>
                                            <img src={img10}></img>                        
                                        </div>
                                        <div className='bottomHalf'>
                                            <p>
                                                This is inspired by the one and only Andrew
                                                Tate. If you get this NFT, we will personally buy you a
                                                Bugatti…scooter and a case of sparkling water. Although he could
                                                use a book on feminism, Andrew is a big advocate of men being men,
                                                and so are we. He discusses the ways in which guys might develop
                                                themselves and become better. We see Andrew as a representation
                                                of overcoming your worries and picking yourself back up when you&#39;re
                                                feeling down, and for that reason, we want to dedicate this NFT to the
                                                Top G himself.
                                            </p>                        
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='bottomBlackBackground'></div> */}
                                {/* <div className="fadeAboveFooter"></div> */}
                                <Footer2/>
                            </div>
                        </div>
                        <div className="fadeAboveFooterJunks"></div>
                        <div className='page-contents-mobile'>
                            <Snackbar
                                ref={snackbarRef}
                                message={snackBarMessage}
                                type={snackBarType}
                            />
                            <div className='title'> Meat the Junks
                            </div>
                            <div className='mobile-junk-row'>
                                <div className='junkDescriptionContainer'>
                                    <h2>ELONgated MUSKeteer</h2>
                                        <img src={img1}></img>
                                        <p>Elon Musk was the first 1/1 that sprang
                                            to our minds and the first person who we wanted to see as a Junk.
                                            He is inspiring a whole generation of innovative thinkers and doers to
                                            steer the ship of humanity in the right direction. Because he is such a
                                            big lover of memes and also of NFTs, with his assistance, we will be
                                            able to impact the lives of a great number of people and disseminate
                                            awareness about mental health and MetaJunks all over Twitter. Elon
                                            is our symbol of innovation and reaching heights never before
                                            thought possible. This NFT is Elon&#39;s and our project&#39;s talisman to
                                            blast past the moon and make it all the way to mars. ​​
                                        </p>
                                </div>
                                <div className='junkDescriptionContainer'>
                                    <div className='topHalf'>
                                            <h2>Mark’s got a Cuban</h2>
                                            <img src={img2}></img>
                                        </div>
                                        <div className='bottomHalf'>
                                            <p>
                                                Inspired by Mark Cuban. He is not only a
                                                shark in the streets and a maverick in the sheets, but he is also a
                                                F*CKING successful businessman and an avid NFT collector. We
                                                know that Mark wants to leave a positive mark on the world with his
                                                significant strides toward revolutionizing the online pharmaceutical
                                                industry, which enables millions of people to obtain the essential
                                                medication they require, a significant number of which assist
                                                individuals with their mental health. We feel that Mark&#39;s vision
                                                matches our aim to improve the world of mental health and help
                                                millions of people, and we look forward to working together to
                                                accomplish these goals.
                                            </p>
                                        </div>
                                </div>
                                <div className='junkDescriptionContainer'>
                                    <div className='topHalf'>
                                        <h2>Gary’s Wee</h2>
                                        <img src={img3}></img>
                                    </div>
                                    <div className='bottomHalf'>
                                        <p>Inspired by the whale Gary Vee. You can see the
                                            cute jersey his grandma knitted and the ugly wires on his earphones.
                                            Gary opened people&#39;s eyes to NFTs, so we opened his pants to

                                            create this phenomenal piece. Gary is an advocate of leading a
                                            happy life and has motivated a great number of individuals to take
                                            action to make positive changes in their lives. Because Gary
                                            demonstrated to the world what can be accomplished with a shift in
                                            mentality, we have honored him with this 1/1.
                                        </p>                                
                                    </div>
                                </div>
                                <div className='junkDescriptionContainer'>
                                    <div className='topHalf'>
                                            <h2>Steve Did it</h2>
                                            <img src={img4}></img>                                        
                                        </div>
                                        <div className='bottomHalf'>
                                            <p>
                                                This Junk is inspired by Steve Will do it. We wanted
                                                to show the world that he also has a large and beautiful heart;
                                                therefore we made the decision to demonstrate how massive and
                                                lovely his Junk is as well. Not only does Steve help those who are
                                                going through difficult times (no, we&#39;re not talking about the strippers),
                                                but he also always shows people the joyful side of life and
                                                demonstrates that life can be exciting and full of new experiences.
                                                Steve always manages to zero in on the reason why we should be
                                                celebrating the moment, no matter what it is. Steve stands as our
                                                symbol to party when we can and to always look for the path that
                                                comes with a good story to tell, which is what his honorary Junk
                                                symbolizes within our project.
                                            </p>                                        
                                        </div>
                                </div>
                                <div className='junkDescriptionContainer'>
                                    <div className='topHalf'>
                                            <h2>Millimeter Defeater</h2>
                                            <img src={img5}></img>                                    
                                        </div>
                                        <div className='bottomHalf'>
                                            <p>
                                                He is the smallest but most courageous Junk
                                                in the collection. Even though he is rather little, he possesses a large
                                                sack in addition to a massive heart. He is here to show people that
                                                size doesn&#39;t matter; what matters is your attitude towards the world
                                                and the strength of your character because it&#39;s not about the size of
                                                the ship; it&#39;s about the motion in the ocean.
                                            </p>                                    
                                        </div>
                                </div>
                                <div className='junkDescriptionContainer'>
                                    <div className='topHalf'>
                                            <h2>The Cock</h2>
                                            <img src={img6}></img>                                  
                                        </div>
                                        <div className='bottomHalf'>
                                            <p>This gorgeous NFT is inspired by &quot;The Rock,&quot; also
                                                known as Dwayne Johnson. Dwayne once said: &quot;As a matter of fact,
                                                asking for help is our superpower, and men, especially us, we fall into
                                                this trap of being really adverse to vulnerability because we always
                                                want to be strong and feel like we can take on the world.&quot; This quote
                                                inspired us even more to create a space where men can get help
                                                anonymously and escape the trap of being adverse to vulnerability.
                                                The Rock serves as a metaphor for both mental and physical
                                                toughness; as a result, we were inspired to craft this stunning work of
                                                art. That and because we really wanted to be able to use “The Cock.”
                                                We’re sure you understand.
                                            </p>                                
                                        </div>
                                </div>
                                <div className='junkDescriptionContainer'>
                                    <div className='topHalf'>
                                            <h2>Big Shamcock</h2>
                                            <img src={img7}></img>                                
                                        </div>
                                        <div className='bottomHalf'>
                                            <p>
                                                This is inspired by a man who has had more jobs
                                                than Johnny Sins. We felt bad that Adam Sandler couldn&#39;t see Shaq&#39;s
                                                Junk, so we decided to show Mr. Sandler what it was all about.
                                                Unfortunately, We tried to fit the Junk true-to-size, but our computer
                                                kept crashing. Shaq stands as a symbol of doing what you love and
                                                playing to the strengths you&#39;ve been given, which is why we
                                                embodied his passion in this honorary 1/1. Get Junked On.
                                            </p>                             
                                        </div>
                                </div>
                                <div className='junkDescriptionContainer'>
                                    <div className='topHalf'>
                                        <h2>Snoop Dog</h2>
                                        <img src={img8}></img>                            
                                        </div>
                                        <div className='bottomHalf'>
                                            <p>
                                                This Junk is inspired by the one and only Snoop
                                                Dogg. Just like his joints, his Junk is never limp. Snoop serves as a
                                                symbol that sometimes the best and most important thing to do, is to
                                                chill the f*ck out. Often men get so caught up in everyday struggles
                                                that they forget to see the bigger picture and realize everything is
                                                okay, which is why this honorary NFT is a vital part of our message.
                                            </p>                             
                                        </div>
                                </div>
                                <div className='junkDescriptionContainer'>
                                    <div className='topHalf'>
                                            <h2>Abella's Danger</h2>
                                            <img src={img9}></img>                          
                                        </div>
                                        <div className='bottomHalf'>
                                            <p>
                                                The young lady known as Danger possesses
                                                a lot of bravery. She is in danger because she is usually used to only
                                                10 junks at a time, and we at Metajunks will serve her with 6,969 of
                                                them. There is nobody that loves Junks more than her, not even us.
                                                She adores them in every conceivable form, irrespective of their
                                                dimensions, hues, or other distinguishing features. We chose her to
                                                be part of the 1/1&#39;s because every man knows who she is, and she
                                                makes men imagine great things. We want her to help us show men
                                                that even if they think their mental health is in danger, they can get
                                                out of it with help. Unless they are literally IN Danger, in which case
                                                we bet they won&#39;t want any help.
                                            </p>                           
                                        </div>
                                </div>
                                <div className='junkDescriptionContainer'>
                                    <div className='topHalf'>
                                            <h2>Andrew's Cobra</h2>
                                            <img src={img10}></img>                        
                                        </div>
                                        <div className='bottomHalf'>
                                            <p>
                                                This is inspired by the one and only Andrew
                                                Tate. If you get this NFT, we will personally buy you a
                                                Bugatti…scooter and a case of sparkling water. Although he could
                                                use a book on feminism, Andrew is a big advocate of men being men,
                                                and so are we. He discusses the ways in which guys might develop
                                                themselves and become better. We see Andrew as a representation
                                                of overcoming your worries and picking yourself back up when you&#39;re
                                                feeling down, and for that reason, we want to dedicate this NFT to the
                                                Top G himself.
                                            </p>                        
                                        </div>
                                </div>
                            </div>
                            <div className="fadeAboveFooter"></div>
                            <Footer/>
                        </div>
                        <div className="fadeAboveFooterJunks"></div>
                    </AnimatedPage>
                </div>
                {/* <div className='backgroundExtenderMeatJunks'>*/}
                </div> 
            {/*</div>
             <div className="fadeAboveFooter"></div> */}
            {/* <Footer/> */}
        </div>
    
    )
}

export default OneOfKinds;